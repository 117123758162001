.rsw-editor {
  border-radius: 0em !important;
  border: 1.25px solid #000 !important;
}

.rdw-option-wrapper {
  height: 20px;
  width: 25px;
}
.rsw-toolbar {
  border-bottom: 1px solid #000 !important;
}

.rdw-link-modal-target-option {
  display: none;
}
.rdw-link-modal-input {
  border: 1px solid #ccc;
}
.rdw-link-modal-btn {
  background-color: #fff;
  color: #000;
  border: 1.25px solid #000;
}

.readonly {
  margin: 5px;
  background-color: #eee;
}

.background-color-gray {
  background-color: #eee;
}

.rdw-inline-wrapper {
  height: 32px;
}

.custom-option {
  min-width: 25px;
  min-height: 25px;
  font-size: 18px;
  margin-bottom: 10px;
  margin-right: 20px;
}

.custom-option-element {
  min-width: 25px;
  min-height: 25px;
  font-size: 18px;
}
