.error {
  border: 2px solid red !important;
}

.eye-position {
  position: absolute;
  right: 10px;
  top: 8px;
  width: 30px;
  text-align: center;
}