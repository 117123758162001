.not_underlined {
  text-decoration: none;
}
.delete_button {
  font-size: 30px;
}
.font-18 {
  font-size: 1.125rem;
}
.icon-40 {
  font-size: 2.5em;
}
.icon-27 {
  font-size: 1.6875em;
}

.tooltip-content {
  background-color: #ddd;
  color: #000;
  font-size: 0.875rem;
  line-height: 20px;
  font-weight: 700;
  max-width: 350px;
}

.div-width {
  width: 400px;
}

.div-width-big {
  width: 480px;
}
