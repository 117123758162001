.image-loader-div-container {
  width: 300px;
}

.image-loader-div-img {
  height: 250px;
  width: 250px;
  display: flex;
  margin: auto;
}
.image-loader-img {
  height: 100%;
  width: 100%;
  margin: auto;
}

input[type='file'] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 25px;
  margin-top: 5px;
}

.font-16 {
  font-size: 16px;
}
