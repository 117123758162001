.presHeader {
  padding: 0.875rem 0.625rem 0.4375rem 0.625rem;
}

.h1Style {
  margin-bottom: 0.625rem;
}

.icon-27 {
  font-size: 1.6875em;
}
