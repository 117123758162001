.paginateBreakLinkClassName {
  border: 0.125rem solid transparent;
  width: 40px;
  height: 40px;
  justify-content: center;
  display: flex;
  text-decoration: none;
}

.paginateNextPreviousLinkClassName {
  border: 0.125rem solid #000;
  width: 40px;
  height: 40px;
  padding-top: 5px;
  justify-content: center;
  display: flex;
  text-decoration: none;
  font-weight: 700;
}

.paginateBreakClassName {
  list-style: none;
  width: 40px;
  height: 40px;
  padding-top: 5px;
  justify-content: center;
  display: flex;
  margin-left: 10px;
}

.paginatePageClassName {
  list-style: none;
  width: 40px;
  height: 40px;

  justify-content: center;
  display: flex;
  margin-left: 10px;
}

.paginatePageLinkClassName {
  border: 0.125rem solid transparent;
  width: 40px;
  height: 40px;
  padding-top: 7px;
  justify-content: center;
  display: flex;
  text-decoration: none;
}
.paginateContainerClassName {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.paginateDisabledClassName {
}

.paginateDisabledLinkClassName {
  color: #ccc;
  border: 0.125rem solid #ccc;
  width: 40px;
  height: 40px;
  padding-top: 5px;
  justify-content: center;
  display: flex;
  text-decoration: none;
  pointer-events: none;
  font-weight: 700;
}

.paginateActiveClassName {
}

.paginateActiveLinkClassName {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.paginatePageLinkClassName:hover,
.paginatePageLinkClassName:focus,
.paginateBreakLinkClassName:hover,
.paginateBreakLinkClassName:focus {
  z-index: 2;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border-color: #ccc;
}

.paginatePageLinkClassName:focus,
.paginateBreakLinkClassName:focus {
  z-index: 3;
  outline: 0.125rem solid #ccc;
  outline-offset: -0.125rem;
}

.paginateNextPreviousLinkClassName:focus,
.paginateNextPreviousLinkClassName:hover {
  color: #fff;
  background-color: #000;
  outline-color: #000;
}

.paginateBreakLinkClassName:hover,
.paginateBreakLinkClassName:focus {
  padding-top: 5px;
  margin-top: -5px;
}
