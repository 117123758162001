@import url('../styles/colors.css');

.header {
  padding-top: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  border-top: 0.125rem solid var(--bs-gray-400);
}

.center-horizontal {
  text-align: left;
}

.technology-reminder {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.4px;
  color: var(--Core-Orange-2);
}
