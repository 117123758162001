.company-card {
  width: 250px;
  height: 570px;
  border: 0.125rem solid var(--bs-gray-400);
  min-width: 250px;
  margin: 5px;
}

.company-div-desc {
  height: 240px;
}
.company-divimg {
  min-height: 320px;
  max-height: 320px;
  margin: 5px;
  display: flex;
  cursor: pointer;
}
.company-divimg-edit {
  height: 100%;
  width: 100%;
  display: flex;
}
.company-img {
  height: 100%;
  width: 100%;
  margin: auto;
}

.company-div-name {
  padding: 1rem;
  font-size: 1.5rem;
  border-top: 0.125rem solid var(--bs-gray-400);
  max-height: 81px;
  cursor: pointer;
}

.company-name {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.company-name:hover {
  color: #f16e00;
}

.company-description {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 2px;
  height: 75px;
}

.company-div-description {
  padding: 1rem;
  font-size: 1rem;

  min-height: 90px;
  max-height: 90px;
}

.company-country {
  padding: 1rem;
  font-size: 1.125rem;
}

.company-datavenue {
  height: 64px;
}

.div-company-datavenue {
  position: absolute;
  margin-top: 255px;
}

.companies-list {
  display: flex;
  max-width: 800px;
  flex-wrap: wrap;
}

.catalog-total-numbers {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.4px;
  color: var(--bs-black);
}

.catalog-criterias-numbers {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.4px;
  color: #f16e00;
}

.filter-title {
  font-size: 1.5rem;
  line-height: calc(26 / 24);
  letter-spacing: -0.0375rem;
  font-weight: 700;
}

.orderby-div {
  max-width: 795px;
}

.orderby-title {
  font-size: 1.5rem;
  line-height: calc(26 / 24);
  letter-spacing: -0.0375rem;
  font-weight: 700;
  color: #f16e00;
}

.errorMessage {
  color: var(--bs-red);
}

.react-datepicker__day--selected {
  background-color: #f16e00 !important;
  color: #000 !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #f16e00 !important;
  color: #000 !important;
}

.form-check {
  width: 4rem !important;
}

.form-label {
  width: 50rem !important;
  font-weight: 500;
}

.div-check {
  width: 21rem !important;
}

.div-check-esim {
  width: 18rem !important;
}

.w-15rem {
  width: 15rem;
}
.w-250 {
  width: 250px;
}

.document-extension {
  width: 40px;
}

.img-no-stretch {
  object-fit: contain;
}
