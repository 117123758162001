.span-header {
  display: flex;
  margin-left: 5px;
}
.div-header {
  display: flex;
  align-items: center;
}

.stepped-process-link::before {
  content: counter(var(--bs-stepped-process-counter)) '. ';
}

.div-header-step {
  max-width: 50px !important;
  margin-left: 10px;
  align-items: flex-start;
  display: flex;
}
.span-header-step {
  margin: auto;
}

.text-white {
  color: #fff;
}

.wizard-footer-div {
  margin-right: 10px;
}
