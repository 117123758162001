.login__container nav{
    border-bottom: 1px solid #999;
}
.login__container nav a{
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: -0.1px;
}
.login__container .particule{
    position: absolute;
    bottom: 0;
    left: 50%;
    top: 0;
    right: 0;
    z-index: 0 !important;
}
.login__container .container{
    z-index: 1000 !important;
}
.login__container h1{
    font-size: 2.125rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -2px;
}
.login__container p.lead{
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: -0.2px;
}
.login__container form input{
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: -0.1px;
    color: #999;
    border: 2px solid #999;
    padding: 10px 20px;
    width: 300px;
}

.login__container form input:focus, .login__container form input:active{
    outline: 0;
}
.login__container form button{
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: -0.2px;
    padding: 10px 20px;
    transition-duration: 0.5s;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}
.border-light {
    border-color: #999 !important;
}

.custom-control .form-group{
    width: 420px;
}
.checkbox{
    width: 13px !important;
}
.error{
    border:2px solid red !important;
}
