.membership-advantages i {
  font-size: 4.125em;
}
.membership-advantages .card-title a {
  color: var(--Very-Dark-Grey);
}
.membership-advantages .card-title a:hover {
  color: var(--Core-Orange-2);
}
@media (max-width: 479.98px) {
  .membership-advantages i {
    font-size: 2.625rem;
  }
}

.icon-40 {
  font-size: 2.5em;
}

.font-size-1-125 {
  font-size: 1.125rem;
}

.line-height-1-5 {
  line-height: 1.5;
}
