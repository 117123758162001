.products-list {
  display: flex;
  max-width: 1050px;
  flex-wrap: wrap;
}

.product-card {
  width: 250px;
  height: 650px;
  border: 0.125rem solid #ddd;
  margin: 5px;
}
.product-divimg {
  min-height: 320px;
  max-height: 320px;
  margin: 5px;
  display: flex;
  cursor: pointer;
}
.product-img {
  object-fit: scale-down;
}
.product-div-desc {
  height: 240px;
}

.product-div-name {
  padding: 1rem;
  font-size: 1.5rem;
  border-top: 0.125rem solid #ddd;
  max-height: 81px;
  cursor: pointer;
}

.product-name:hover,
.product-company:hover,
.device-company:hover {
  color: #f16e00;
}

.device-company {
  cursor: pointer;
}
.product-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.product-div-company {
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.25rem;
  cursor: pointer;
}

.product-company {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.product-div-price {
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.5rem;
}

.product-price {
  font-weight: bold;
}

.product-div-features {
  padding: 1rem;
  font-size: 1rem;

  min-height: 90px;
  max-height: 90px;
}

.product-features {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 2px;
  height: 75px;
}

.product-div-type {
  padding: 1rem;
  font-size: 1.25rem;
}

.product-type {
  font-weight: bold;
}

.imgLabel {
  height: 2.5rem;
}

.add {
  width: 9rem !important;
}

.imgProduct {
  object-fit: contain;
}

.imgLabel {
  background-color: black;
  height: 2.5rem;
}

.add {
  width: 9rem !important;
}

.description {
  min-height: 4.5rem !important;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.btn-block {
  width: 100%;
  display: block;
}

.w-250 {
  width: 250px;
}

.not_underlined {
  text-decoration: none;
}
.delete_button {
  font-size: 30px;
  color: black;
}

.react-datepicker-wrapper {
  display: flex !important;
}

.col-version {
  width: 300px;
}

.vertical-align-middle {
  vertical-align: middle;
}

.image-div {
  height: 320px;
  width: 350px;
}

.image-header-div {
  height: 60px;
}

.greenBackgroundColor {
  background-color: #28a745;
  padding: 3px;
}

.device-image-p {
  margin-bottom: 2px;
}

.font-14 {
  font-size: 14px;
}

.font-28 {
  font-size: 28px;
}

.form-check {
  width: 4rem !important;
}

.form-check-long-label {
  width: 85% !important;
}

.form-check-ESG_CSR {
  width: 100% !important;
}

.div-check {
  width: 21rem !important;
}

.div-check-esim {
  width: 18rem !important;
}

.group-heading-collapse {
  display: inline-flex;
  vertical-align: middle;
  margin-right: 0.625rem;
  content: '';
  border-top: 0.4375rem solid;
  border-left: 0.4375rem solid transparent;
  border-bottom: 0;
  border-right: 0.4375rem solid transparent;
  margin-left: 5px;
  margin-bottom: 5px;
}

.group-heading-collapse-rotate {
  transform: rotate(-90deg);
}

.link-button {
  border: none;
  background: none;
}

.link-button:disabled {
  color: #ccc;
}

.text-primary-orange {
  color: #f16e00;
}

.device-version-readonly-date {
  margin-top: 16px;
}

.popupMoreInformation {
  height: 20px;
  line-height: 20px;
  width: 20px;
  color: #9aa0a6;
  position: relative;
  display: inline-block;
  fill: currentColor;
}
